<template>
  <div class="ad-home">
    <ad-header :bg="homeBG" :mobileBg="homeBG">
      <!-- 主页 - PC端 -->
      <div class="banner-text-container">
        <div class="banner-text">
          <div class="en-title">
            <h4>{{ siteBrief }}</h4>
            <p>{{ siteIntroduce }}</p>
          </div>
          <div class="line">
            <img src="../../assets/images/pic_rectangular.png">
          </div>
          <p>{{ siteName }}</p>
        </div>
        <div class="banner-download">
          <div @click="onDownload('android')">
            <img src="../../assets/images/icon-android.png">
            <span>Android 下载</span>
          </div>
          <div @click="onDownload('ios')">
            <img src="../../assets/images/icon-ios.png">
            <span>iphone 下载</span>
          </div>
        </div>
      </div>
      <!-- 主页 - 移动端 -->
      <div class="banner-text-container-mobile">
        <h4>{{ siteBrief }}</h4>
        <p>{{ siteIntroduce }}</p>
        <div class="line">
          <img src="../../assets/images/pic_rectangular.png">
        </div>
        <p>{{ siteName }}</p>
        <div class="btn-box">
          <div class="btn" @click="onDownload('mobile')">立即下载</div>
          <img alt="" class="to" src="../../assets/images/mobile/to.png">
        </div>
      </div>
    </ad-header>
    <ad-navs />
    <!-- 产品介绍 - 桌面端 -->
    <div class="advantage-container">
      <div class="title">
        <div class="en-title">
          <img alt="" src="https://moemone-cms-test.oss-cn-shenzhen.aliyuncs.com/ringle/product%20dark.png">
        </div>
        <div class="cn-title">应用介绍</div>
      </div>
      <div class="advantage-list">
        <div v-for="(item, index) in introduceData" :key="index" class="advantage-item">
          <img :src="item.image" alt="">
          <div class="content">
            <h3>{{ item.title }}</h3>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品介绍 - 移动端 -->
    <div class="advantage-container-mobile">
      <div class="title">
        <div class="en-title">
          <img src="https://moemone-cms-test.oss-cn-shenzhen.aliyuncs.com/ringle/product%20dark.png">
        </div>
        <div class="cn-title">应用介绍</div>
      </div>
      <div class="advantage-list">
        <div v-for="(item, index) in introduceData" :key="index" class="advantage-item">
          <img :src="item.image">
          <div class="content">
            <h3>{{ item.title }}}</h3>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 - 桌面端 -->
    <div v-if="partnerData" class="client-container">
      <div class="client">
        <div class="title">
          <div class="en-title">
            <img src="../../assets/images/clients 2.png">
          </div>
          <div class="cn-title">合作企业</div>
        </div>
        <div v-if="partnerData.length > 0" class="client-list">
          <div v-for="(item, index) in partnerData" :key="index" class="client-item">
            <img :src="item">
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 - 移动端 -->
    <div v-if="partnerData" class="client-container-mobile">
      <img src="../../assets/images/mobile/hezuoqiyebg.png" style="width: 100%">
      <img src="../../assets/images/mobile/hezuoqiye1bg.png" style="width: 100%" class="client-mobile-bg">
      <div class="client">
        <div class="title">
          <div class="en-title">
            <img src="../../assets/images/mobile/clients-title-en.png">
          </div>
          <div class="cn-title">合作企业</div>
        </div>
        <div
          v-if="partnerData.length > 0"
          class="client-list"
        >
          <div
            v-for="(item, index) in partnerData"
            :key="index"
            class="client-item"
          >
            <img :src="item">
          </div>
        </div>
      </div>
    </div>
    <!-- 企业招聘 - 移动端 -->
    <div v-if="positionData" class="job-container">
      <div class="title">
        <div class="en-title">
          <img src="https://moemone-cms-test.oss-cn-shenzhen.aliyuncs.com/ringle/join%20us%20dark.png">
        </div>
        <div class="cn-title">加入我们</div>
      </div>
      <div v-if="positionData.length > 0" class="job-list">
        <div
          v-for="(item, index) in positionData"
          :key="index"
          class="job-item"
          @click="onJobHandler(item)"
        >
          <div class="job-name">
            <div>{{ item.name }}</div>
            <p>{{ item.salary }}</p>
          </div>
          <div class="desc">{{ item.description }}</div>
          <div class="company">
            <p class="name">
              <img :src="item.company_logo">{{ item.company_name }}
            </p>
            <p class="icon"><img src="../../assets/images/icon-right.png"></p>
          </div>
        </div>
      </div>
    </div>

    <ad-footer />
    <!-- 扫码下载悬浮窗 -->
    <!--<ad-float-download />-->

    <el-dialog :visible.sync="jobDialogVisible"
               custom-class="job-dialog"
               width="360px"
    >
      <div class="job-detail-contailer">
        <img src="二维码图片">
        <p>扫码下载APP，了解更多岗位详情</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { DOWNLOAD_LINK, IMAGES } from "@/settings"
import store from "@/store"
import enterpriseApi from '@/api/enterprise'

export default {
  name: "AdHomeComponent",
  data() {
    return {
      jobDialogVisible: false,
      introduceData: [],
      positionData: null,
      partnerData: null,
      jsonData: {
        app_url: {
          ios: DOWNLOAD_LINK.IOS,
          android: DOWNLOAD_LINK.ANDROID,
        }
      },
      img: null,
    }
  },
  computed: {
    siteName() { return store.getters.siteName },
    siteBrief() { return store.getters.siteBrief },
    siteIntroduce() { return store.getters.siteIntroduce },
    homeBG() { return store.getters.resourceHomeBG }
  },
  mounted() {
    store.dispatch("resource/getInfo")
    store.dispatch('enterprise/getInfo')
    store.dispatch('site/getInfo')
    this.getIntroduce()
    // this.getPartners()
    // this.getJobs()
  },
  methods: {
    async getIntroduce() {
      let { data } = await enterpriseApi.readIntroduce()
      this.introduceData = data
      console.log("产品数据：", this.introduceData)
    },
    async getPartners() {
      let { data } = await enterpriseApi.readPartners()
      this.partnerData = data
      console.log("合作伙伴：", this.partnerData)
    },
    async getJobs() {
      let { data } = await enterpriseApi.readJobs()
      this.positionData = data
      console.log("招聘数据：", this.positionData)
    },
    onJobHandler() {
      this.jobDialogVisible = true
    },
    onDownload(platform) {
      if (platform === "mobile") {
        let u = navigator.userAgent
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        if (isAndroid) {
          window.open(this.jsonData.app_url["android"])
        }
        if (isIOS) {
          window.open(this.jsonData.app_url["ios"])
        }
        return
      }
      window.open(this.jsonData.app_url[platform])
    },
  },
}
</script>

<style lang="scss" scoped>
.ad-home {
  width: 100%;
  box-sizing: border-box;

  .banner-text-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    .banner-text {
      width: 500px;
      flex: 1;
      margin: 32px 16px;
      .en-title {
        h4 {
          font-size: 3.0rem;
          font-weight: 900;
          line-height: 3.8rem;
          color: #FFFFFF;
          width: 40rem;
        }
        p {
          padding: 2.2rem 0 1.0rem 0;
          font-size: 3.0rem;
          font-weight: 900;
          line-height: 3.0rem;
          ccolor: #FFFFFF;
          width: 40rem;
        }
      }
      .line {
        margin: 78px 0px 80px 0px;
      }
      p {
        font-size: 3.0rem;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 1px;
        width: 40rem;
      }
    }
    .banner-download {
      width: 500px;
      height: 60px;
      margin: 32px 16px;
      display: flex;
      align-items: center;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 16px;
        &:nth-child(1) {
          width: 192px;
          height: 60px;
          background: #ffffff;
          box-shadow: 0px 30px 28px 0px rgba(0, 0, 0, 0.1);
          border-radius: 8px;

          font-size: 14px;
          font-weight: 300;
          color: #222222;
          img {
            width: 26px;
            height: 26px;
            margin-right: 10px;
          }
        }
        &:last-child {
          width: 192px;
          height: 60px;
          background: #ffffff;
          box-shadow: 0px 30px 28px 0px rgba(0, 0, 0, 0.1);
          border-radius: 8px;

          font-size: 14px;
          font-weight: 300;
          color: #222222;
          img {
            width: 26px;
            height: 26px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .banner-text-container-mobile {
    display: none;
  }

  .advantage-container {
    width: 100%;
    height: 760px;
    box-sizing: border-box;
    background: #f6f9fa url("../../assets/images/icon-bg1.png") no-repeat center;
    background-size: cover;
    padding-top: 100px;
    .title {
      width: 100%;
      text-align: center;
      .en-title {
        img {
          width: 173px;
          height: 30px;
        }
      }
      .cn-title {
        font-size: 20px;
        font-weight: 600;
        color: #111111;
        margin-top: 18px;
      }
    }
    .advantage-list {
      display: flex;
      align-items: center;
      max-width: 880px;
      margin: 80px auto auto auto;
      .advantage-item {
        width: 268px;
        height: 320px;
        box-sizing: border-box;
        padding: 18px;

        background: #ffffff;
        box-shadow: 0px 30px 28px 0px rgba(235, 241, 244, 0.66);
        border-radius: 20px;

        margin-left: 36px;

        &:first-child {
          margin-left: 0px;
        }

        img {
          width: 100px;
          height: 118px;
        }

        .content {
          h3 {
            font-size: 16px;
            font-weight: 500;
            color: #222222;
            margin-top: 36px;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            color: #454545;
            line-height: 20px;
            margin-top: 16px;
          }
        }
      }
    }
  }
  .advantage-container-mobile {
    display: none;
  }

  .client-container {
    box-sizing: border-box;
    width: 100%;
    margin: 0px auto;
    background: #f6f9fa;

    .client {
      box-sizing: border-box;
      max-width: 1630px;
      height: 806px;
      margin: 0px auto;
      background: url("../../assets/images/icon-bg2.png") no-repeat center;
      padding-top: 100px;
      .title {
        width: 100%;
        text-align: center;
        .en-title {
          img {
            //width: 120px;
            height: 52px;
          }
        }
        .cn-title {
          font-size: 20px;
          font-weight: 600;
          color: #111111;
          margin-top: 18px;
        }
      }
      .client-list {
        width: 1180px;
        margin: 60px auto auto auto;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        .client-item {
          width: 268px;
          height: 194px;
          line-height: 194px;
          background: #ffffff;
          border-radius: 20px;
          text-align: center;
          margin-bottom: 30px;
          &:nth-child(2),
          &:nth-child(6),
          &:nth-child(10),
          &:nth-child(14) {
            margin: 0px 36px;
          }
          &:nth-child(3),
          &:nth-child(7),
          &:nth-child(11),
          &:nth-child(15) {
            margin-right: 36px;
          }
          img {
            width: 180px;
            height: 90px;
            margin-top: 52px;
          }
        }
      }
    }
  }
  .client-container-mobile {
    display: none;
  }

  .job-container {
    width: 100%;
    box-sizing: border-box;
    padding-top: 100px;
    .title {
      text-align: center;
      .en-title {
        img {
          height: 36px;
        }
      }
      .cn-title {
        font-size: 20px;
        font-weight: 600;
        color: #111111;
        margin-top: 18px;
      }
    }
    .job-list {
      max-width: 1180px;
      box-sizing: border-box;
      margin: 60px auto 138px auto;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      .job-item {
        width: 572px;
        height: 174px;
        box-sizing: border-box;
        border-radius: 20px;
        border: 1px solid #e6e6e6;
        margin-bottom: 30px;
        cursor: pointer;
        padding: 36px 42px 38px 42px;
        &:nth-child(odd) {
          margin-right: 32px;
        }
        &:hover {
          background: #ffffff;
          box-shadow: 0px 24px 30px 0px rgba(235, 241, 244, 0.6);
          border: none;
          .icon {
            display: block !important;
          }
        }
        .job-name {
          display: flex;
          align-items: center;
          div {
            flex: 1;
            font-size: 16px;
            font-weight: 600;
            color: #111111;
          }
          p {
            font-size: 20px;
            font-weight: 900;
            color: #222222;
          }
        }
        .desc {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          margin-top: 4px;
        }
        .company {
          display: flex;
          align-items: center;
          margin-top: 42px;
          .name {
            flex: 1;
            font-size: 14px;
            font-weight: 400;
            color: #222222;
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              margin-right: 7px;
            }
          }
          .icon {
            display: none;
            img {
              width: 18px;
              height: 10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .ad-home {
    .banner-text-container {
      display: none !important;
    }
    .banner-text-container-mobile {
      display: block !important;
      height: 100%;
      position: relative;
      h4 {
        padding-top: 144px;
        margin: 0 17px;
        font-size: 24px;
        color: #ffffff;
        letter-spacing: 2px;
      }
      p {
        margin: 0 17px;
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 2px;
      }
      p:first-of-type {
        margin-top: 8px;
      }
      .line {
        margin: 48px 17px !important;
        padding: 0px !important;
        img {
          width: 54px;
          height: 1px;
          margin: 0px;
          padding: 0px !important;
        }
      }
      .btn-box {
        width: 100%;
        position: absolute;
        bottom: 43px;
        .btn {
          margin: 0 auto;
          width: 300px;
          height: 44px;
          line-height: 44px;
          border-radius: 22px;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          background: #0091ff;
        }
        .to {
          display: block;
          width: 26px;
          height: 8px;
          margin: 20px auto auto auto;
          padding: 0px !important;
        }
      }
    }

    .advantage-container {
      display: none;
    }
    .advantage-container-mobile {
      display: block !important;
      width: 100%;
      box-sizing: border-box;
      position: relative;
      background-image: url('../../assets/images/mobile/womendeyoushibg.png');
      background-size: cover;
      .title {
        padding-top: 40px;
        width: 100%;
        text-align: center;
        .en-title {
          img {
            width: 121px;
            height: 24px;
          }
          font-size: 1.6rem;
          font-weight: 1200;
        }
        .cn-title {
          font-size: 1.4rem;
          font-weight: 800;
          color: #111111;
          margin-top: 0px;
        }
      }
      .advantage-list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin: 25px auto auto auto;
        padding: 0 15px;
        box-sizing: border-box;
        .advantage-item {
          width: 165px;
          height: 220px;
          box-sizing: border-box;
          padding: 10px 9px 15px 9px;

          background: #fff;
          border-radius: 12px;
          margin: 0 6px 16px 6px;

          img {
            width: 59px;
            height: 70px;
            margin: 0px !important;
            padding: 0px !important;
          }

          .content {
            h3 {
              font-size: 14px;
              font-weight: 500;
              color: #222222;
              margin-top: 11px;
            }
            p {
              font-size: 12px;
              font-weight: 300;
              color: #454545;
              line-height: 20px;
              margin-top: 7px;
            }
          }
        }
      }
    }

    .client-container {
      display: none !important;
    }
    .client-container-mobile {
      display: block !important;
      box-sizing: border-box;
      width: 100%;
      height: 600px;
      margin: 0px auto;
      background: #f6f9fa;
      padding: 0px 15px;
      position: relative;

      .client-mobile-bg {
        width: 345px;
        position: absolute;
        left: 50%;
        top: 98px;
        transform: translate(-50%, 0%);
      }

      .client {
        box-sizing: border-box;
        width: 345px !important;
        max-width: 345px !important;
        margin: 0px auto;
        padding-top: 26px;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translate(-50%, 0%);
        .title {
          width: 100%;
          text-align: center;
          .en-title {
            img {
              width: 121px;
              height: 24px;
            }
          }
          .cn-title {
            font-size: 15px;
            font-weight: 800;
            color: #111111;
            margin-top: 0px;
          }
        }
        .client-list {
          width: 100%;
          margin: 28px auto auto auto;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          box-sizing: border-box;
          padding: 25px 20px;
          // background: url("../../assets/images/mobile/hezuoqiye1bg.png") no-repeat
          //   0px 0px;
          // background-size: 100%;
          .client-item {
            box-sizing: border-box;
            width: 144px;
            height: 97px;
            line-height: 97px;
            background: #ffffff;
            border-radius: 10px;
            text-align: center;
            margin-bottom: 15px;
            overflow: hidden;
            &:nth-child(even) {
              margin-left: 17px;
            }
            img {
              width: 100%;
              margin-top: 13.43px;
            }
          }
        }
      }
    }

    .job-container {
      display: none !important;
    }
  }
}
</style>
